import React from "react";
import "./App.scss";
import { Header } from "./components/shared/Header/Header";
import { Footer } from "./components/shared/Footer/Footer";
import { VerificationSteps } from "./pages/VerificationSteps/VerificationSteps";

function App() {
  return (
    <div className="App">
      <Header />

      <div className="MainContent Column">
        <VerificationSteps />
      </div>

      <Footer />
    </div>
  );
}

export default App;
