import { environment } from "../../environment";
import {
  UserCountryResponse,
  UserResponse,
  UserStepResponse,
} from "../models/user.model";

export interface UpdateUserCountryDto {
  country_code?: string;
}

// Fetch the current user details
export const getUser = async (): Promise<UserResponse | null> => {
  const response = await fetch(environment.backendUrl + "/user");

  if (!response.ok) {
    return null;
  }

  return response.json() as Promise<UserResponse>;
};

// Fetch the current user step in the verification process
export const getUserStep = async (): Promise<UserStepResponse> => {
  const response = await fetch(environment.backendUrl + "/user/step");

  if (!response.ok) {
    throw new Error("Failed to fetch user step"); // Handle error cases appropriately
  }

  return response.json() as Promise<UserStepResponse>;
};

// Fetch the current user's country
export const getUserCountry = async (): Promise<UserCountryResponse> => {
  const response = await fetch(environment.backendUrl + "/user/country");

  if (!response.ok) {
    throw new Error("Failed to fetch user country"); // Handle error cases appropriately
  }

  return response.json() as Promise<UserCountryResponse>;
};

// Update the user's country code
export const updateUserCountry = async (
  countryCode: string
): Promise<UserResponse | null> => {
  const body: UpdateUserCountryDto = { country_code: countryCode };

  const response = await fetch(environment.backendUrl + "/user/country", {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    return null; // Handle error cases appropriately
  }

  return response.json() as Promise<UserResponse>;
};
