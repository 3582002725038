export const COUNTRIES = [
  {
    value: "AT",
    flag: "🇦🇹",
    label: "Austria",
  },
  {
    value: "DE",
    flag: "🇩🇪",
    label: "Germany",
  },
];
