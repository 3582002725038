import React from "react";
import "./VerificationConfirmation.scss";
import shield from "../../../assets/icons/shield.svg";
import logoShort from "../../../assets/icons/trusted-logo-short.svg";
import { Button } from "../../components/shared/Button/Button";
import { VerifiedCard } from "../../components/shared/VerifiedCard/VerifiedCard";

export type VerificationConfirmationProps = {
  goToPreviousStep: () => void;
  companyName: string;
};

export const VerificationConfirmation = (
  props: VerificationConfirmationProps
): React.JSX.Element => {
  const { goToPreviousStep, companyName } = props;

  return (
    <div className="ColumnCenterSpaceBetween">
      <VerifiedCard />

      <div className="GreyContainer Column PrivacyInfo">
        <p>{companyName} wants to verify you are over 18 years old.</p>
        <div className="Divider"></div>
        <div className="Row">
          <img src={shield} width={20} height={20} alt="Schield" />
          <div className="PrivacyTextBox">
            <p className="Bold">Full privacy</p>
            <p>No personal data is shared with {companyName}.</p>
          </div>
        </div>
      </div>

      <div className="ColumnActions">
        <Button onClick={() => {}}>
          <img width={24} height={24} src={logoShort} />
          Verified with Trusted
        </Button>
      </div>
    </div>
  );
};
