import React, { useEffect, useState } from "react";
import "./IdPassportVerification.scss";
import { CountryDropdown } from "../../components/shared/CountryDropdown/CountryDropdown";
import { Button } from "../../components/shared/Button/Button";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getUserCountry, updateUserCountry } from "../../services/user.service";
import { UserCountryResponse } from "../../models/user.model";

export type IdPassportVerificationProps = {
  countryCodeSaved: () => void;
  goToPreviousStep: () => void;
};

export const IdPassportVerification = (
  props: IdPassportVerificationProps
): React.JSX.Element => {
  const { countryCodeSaved, goToPreviousStep } = props;
  const [countryCode, setCountryCode] = useState<string>();
  const [isCodeInvalid, setIsCodeInvalid] = useState<boolean>(false);
  const {
    data: countryData,
    isRefetching,
    isLoading,
    refetch: refetchCountry,
  } = useQuery<UserCountryResponse | null, Error>({
    queryKey: ["getUserCountry"],
    queryFn: getUserCountry,
    enabled: false,
  });
  const updateUserRequest = useMutation({
    mutationFn: updateUserCountry,
    onSuccess: (data) => {
      if (data?.country_code) {
        countryCodeSaved && countryCodeSaved();
      }
    },
  });

  useEffect(() => {
    refetchCountry();
  }, []);

  const handleNextClick = () => {
    if (!countryCode) {
      setIsCodeInvalid(true);
      return;
    }

    updateUserRequest.mutate(countryCode);
  };

  const handleCountryCodeChange = (value: string) => {
    setIsCodeInvalid(false);
    setCountryCode(value);
  };

  return isRefetching || isLoading ? (
    <></>
  ) : (
    <div className="IdPassportVerification ColumnCenterSpaceBetween">
      <div className="SectionHeader">
        <h1>ID / Passport Verification</h1>
        <p>Please select the country where your ID document was issued.</p>
        <CountryDropdown
          onCountryChange={handleCountryCodeChange}
          country={countryData?.country || undefined}
          markAsInvalid={isCodeInvalid}
        />
      </div>

      <div className="ColumnActions">
        <Button onClick={handleNextClick}>Next</Button>
        {/*<Button style="secondary" onClick={goToPreviousStep}>*/}
        {/*  Back*/}
        {/*</Button>*/}
      </div>
    </div>
  );
};
