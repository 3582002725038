import React, { useState } from "react";
import camera from "../../../../assets/icons/camera-white.svg";
import PassportScanner from "../../../pages/PassportScanner/passport-scanner";
import { Button } from "../Button/Button";

export type PhotoInputProps = {
  onPhotoTaken: (file: File) => void;
  isDisabled?: boolean;
};

export const PhotoInput = ({
  onPhotoTaken,
  isDisabled,
}: PhotoInputProps): React.JSX.Element => {
  const [openScanner, setOpenScanner] = useState(false);

  const handlePhotoCaptured = (photo: File) => {
    onPhotoTaken(photo);
    setOpenScanner(false);
  };

  return openScanner ? (
    <PassportScanner
      closeScanner={() => setOpenScanner(false)}
      photoCaptured={handlePhotoCaptured}
    />
  ) : (
    <Button onClick={() => setOpenScanner(true)} isDisabled={isDisabled}>
      <img src={camera} width={32} height={32} alt="camera" />
    </Button>
  );
};
