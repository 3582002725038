export interface UserResponse {
  uuid: string;
  email: string;
  age: string;
  country_code?: string;
  hashed_password: string;
  email_verified: boolean;
  phone_verified: boolean;
  phone_country_code?: string;
  failed_sms?: string[];
  failed_passwords?: string[];
}

export interface UserStepResponse {
  step: UserStep | null;
}

export interface UserCountryResponse {
  country: string;
}

export enum UserStep {
  EMAIL_VERIFICATION = "EMAIL_VERIFICATION",
  PHONE_VERIFICATION = "PHONE_VERIFICATION",
  COUNTRY_VERIFICATION = "COUNTRY_VERIFICATION",
  PASSPORT_VERIFICATION = "PASSPORT_VERIFICATION",
  LIVENESS_VERIFICATION = "LIVENESS_VERIFICATION",
  VERIFICATION_SUCCESS = "VERIFICATION_SUCCESS",
}
